import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "delete" }
const _hoisted_2 = { class: "delete__wrap" }
const _hoisted_3 = { class: "delete__button-group" }
const _hoisted_4 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "delete__title" }, [
        _createTextVNode(" User with this email does not exist."),
        _createElementVNode("br"),
        _createTextVNode(" Please enter correct email ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { for: "phone" }, "Enter your email to confirm", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "email",
          placeholder: "Email",
          class: "delete__input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.email) = $event))
        }, null, 512), [
          [_vModelText, $data.email]
        ])
      ]),
      _createElementVNode("div", {
        class: "delete__button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.sendEmail()))
      }, "Send code"),
      ($data.errorData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.errorData?.email[0]), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}